.home-container {
  width: 100%;
  /*display: flex;*/
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container01 {
  width: 100%;
  /*height: 3565px;*/
  display: flex;
  position: relative;
  align-items: flex-end;
  border-radius: 4px;
  justify-content: flex-start;
}
.home-first {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 1017px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-image {
  width: 100%;
  height: 1008px;
  opacity: 0.50;
  z-index: 1;
  position: relative;
  align-self: stretch;
  object-fit: cover;
}
.home-navbar-interactive {
  width: 100%;
  height: 145px;
  display: flex;
  z-index: 999;
  position: absolute;
  align-items: center;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  justify-content: space-between;
}
.home-image1 {
  width: 120px;
  height: 106px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.home-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.home-text {
  color: black;
  font-size: 25px;
  margin-right: 20px;
  text-transform: capitalize;
  background:none;
    border:none;
    cursor: pointer;
}
.home-text01 {
  color: black;
  font-size: 25px;
  margin-left: 32px;
  margin-right: 20px;
  text-transform: capitalize;
  background:none;
    border:none;
    cursor: pointer;
}
.home-text02 {
  font-size: 25px;
  margin-left: 32px;
  margin-right: 20px;
  text-transform: capitalize;
  background:none;
    border:none;
    cursor: pointer;
}
.home-text03 {
  font-size: 25px;
  margin-left: 32px;
  margin-right: 20px;
  text-transform: capitalize;
  background:none;
    border:none;
    cursor: pointer;
}
.home-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-login {
  color: rgb(82, 78, 78);
  font-size: 25px;
  margin-left: 20px;
  border-width: 0px;
  margin-right: 20px;
  background-color: rgba(255, 255, 255, 0);
}
.home-burger-menu {
  display: none;
}
.home-icon {
  width: 16px;
  height: 16px;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 200;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.9);
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  justify-content: space-between;
}
.home-logo {
  height: 4rem;
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: 16px;
  height: 16px;
}
.home-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-text04 {
  font-size: 20px;
  margin-bottom: 16px;
}
.home-text05 {
  font-size: 20px;
  margin-bottom: 16px;
}
.home-text06 {
  font-size: 20px;
  margin-bottom: 16px;
}
.home-text07 {
  font-size: 20px;
  margin-bottom: 16px;
}
.home-text08 {
  margin-bottom: 16px;
}
.home-buttons1 {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-login1 {
  font-size: 20px;
  margin-right: 32px;
}
.home-icon04 {
  width: 22px;
  height: 22px;
  margin-right: 32px;
}
.home-icon06 {
  width: 22px;
  height: 22px;
  margin-right: 32px;
}
.home-icon08 {
  width: 22px;
  height: 22px;
}
.home-container02 {
  top: 361px;
  flex: 0 0 auto;
  left: 212px;
  color: black;
  width: 775px;
  height: 437px;
  margin: auto;
  display: flex;
  z-index: 1;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-end;
}
.home-text11 {
  width: 777px;
  height: 68px;
  font-size: 50px;
}
.home-text12 {
  color: #612601;
  bottom: 100px;
  position: absolute;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
}
.home-second {
  top: 1007px;
  flex: 0 0 auto;
  right: 0px;
  width: 100%;
  height: 694px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: #fcf8dd;
}
.home-product {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container03 {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  align-items: flex-start;
  /*border-color: #f1bd82;
  border-style: solid;
  border-width: 5px;
  border-radius: 4px;
  */
  justify-content: center;
  background-color: #fcf8dd;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container04 {
  flex: 1;
  margin: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.product_text{
  font-size:25px;

}
.home-text13 {
  font-weight: 800;
  font-size: 50px;
}
.home-text14 {
  color: #595959;
  margin-top: 25px;
  text-align: left;
  margin-bottom: 32px;
}
.home-container05 {
  flex: 2;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.home-container06 {
  flex: 1;
  height: 450px;
  display: flex;
  position: relative;
  max-width: 450px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  padding-top: 32px;
  margin-right: 32px;
  padding-left: 32px;
  border-radius: 10%;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: 32px;
  background-color: white;
}
.home-text18 {
  color: black;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.home-image2 {
  width: auto;
  height: 70%;
  object-fit: cover;
  border-radius: 5%;
}
.home-button {
  color: white;
  margin-top: auto;
  padding-top: 16px;
  border-width: 0px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 16px;
  background-color: #595959;
}
.home-container07 {
  flex: 1;
  color: white;
  height: 450px;
  display: flex;
  max-width: 450px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  transition: 0.3s;
  align-items: center;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: 32px;
  background-color: white;
  margin: 15px;
}
.home-container07:hover {
  transform: scale(1.02);
}
.home-text19 {
  color: black;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.home-image3 {
  width: auto;
  height: 70%;
  object-fit: cover;
  border-radius: 5%;
}
.home-image4 {
  cursor: pointer;
}
.home-button1 {
  font-size: 20px;
  color: white;
  margin-top: 10px;
  padding-top: 16px;
  border-width: 0px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 16px;
  background-color: #595959;
}
.home-third {
  top: 1699px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 800px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  margin-bottom: auto;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f1ece2;
}
.home-heading {
  font-size: 50px;
  font-weight: 800;
  margin-top: 80px;
  margin-bottom: 30px;
}
.home-teams {
  top: 190px;
  left: 0px;
  right: 0px;
  width: 100%;
  margin: auto;
  display: flex;
  padding: 20px;
  position: absolute;
  max-width: 1400px;
  align-items: center;
  justify-content: space-between;
}
.home-container1 {
  display: flex;
  align-items: center;
  margin-right: 32px;
  justify-content: space-between;
}
.home-container2 {
  display: flex;
  align-items: center;
  margin-right: 32px;
  justify-content: space-between;
}
.home-container3 {
  display: flex;
  align-items: center;
  margin-right: 32px;
}
.home-fourth {
  top: 2490px;
  flex: 0 0 auto;
  right: 0px;
  width: 100%;
  height: 1050px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: #f5f1ed;
}
.home-container08 {
  top: 10px;
  flex: 0 0 auto;
  left: 0x;
  width: 100%;
  height: 200px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.home-text20 {
    font-size: 50px;
    font-weight: 800;
}
.home-container09 {
  top: 230px;
  flex: 0 0 auto;
  left: 162px;
  width: 80%;
  height: 622px;
  display: flex;
  position: absolute;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container10 {
    width: 55%;
    height: 520px;
    display: flex;
    align-items: center;
    border-color: white;
    border-style: dashed;
    border-width: 2px;
    border-radius: 150px 150px 0 150px;
    justify-content: flex-start;
    background-color: white;
}
.home-form {
  width: 70%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin:auto;
}
.home-text21 {
  margin: 10px;
  font-size: 25px;
}
.home-textinput {
  padding: 5px;
  font-size: 25px;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.home-text22 {
  margin: 10px;
  font-size: 25px;
}
.home-textinput1 {
  padding: 5px;
  font-size: 25px;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.home-text23 {
  margin: 10px;
  font-size: 25px;
}
.home-textarea {
  width: 90%;
  height: 148px;
  padding: 5px;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.home-button2 {
  color: white;
  margin: 10px;
  background-color: black;
}
.home-text24 {
  font-size: 22px;
}
.home-container11 {
    top: 200px;
    right: 0px;
    width: 45%;
    height: 447px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    background-color: #373636;
    border-radius: 0 150px 150px 150px;
}
.home-text27 {
  top: 28px;
  left: 0px;
  color: rgb(251, 143, 9);
  height: 45px;
  position: absolute;
  font-size: 35px;
  align-self: stretch;
  margin-left: 100px;
  margin-right: 10px;
}

.home-text28 {
  top: 80px;
  left: 0px;
  color: white;
  position: absolute;
  font-size: 25px;
  margin-left: 100px;
  margin-right: 10px;
}
.home-text29 {
  top: 139px;
  left: 0px;
  color: rgb(251, 143, 9);
  position: absolute;
  font-size: 35px;
  align-self: stretch;
  margin-left: 100px;
  margin-right: 10px;
}
.home-text30 {
  top: 190px;
  left: 1px;
  color: white;
  position: absolute;
  font-size: 25px;
  margin-left: 100px;
  margin-right: 10px;
}
.emailurl{
  font-size: 25px;
  /* color: white; */
}
.home-text31 {
  
  color: white;
  width: 80%;
  bottom: 70px;
  position: absolute;
  font-size: 27px;
  font-weight: 700;
  margin-left:15%;
  bottom: 40px;
}
.col{
  text-align: center;
}
.col img{
  width:75%;
  margin: 0 20px;

  --s: 15px;  /* size of the frame */
  --b: 2px;   /* border thickness */
  --c: #668284;
  

  aspect-ratio: 1;
  object-fit: cover;
  padding: calc(2*var(--s));
  --_g: var(--c) var(--b),#0000 0 calc(100% - var(--b)),var(--c) 0;
  background:
    linear-gradient(      var(--_g)) 50%/100% var(--_i,100%) no-repeat,
    linear-gradient(90deg,var(--_g)) 50%/var(--_i,100%) 100% no-repeat;
  outline: calc(var(--w)/2) solid #0009;
  outline-offset: calc(var(--w)/-2 - 2*var(--s));
  transition: .4s;
 

}
.col img:hover {
  outline: var(--b) solid var(--c);
  outline-offset: calc(var(--s)/-2);
  --_i: calc(100% - 2*var(--s));
}
.col div{
  font-size: 20px;
  text-align: center;
}
.col_c{
  margin: 20px;
}
.footer-basic{
  left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    z-index: 1000;
    color: rgba(0,0,0,.5);
    text-shadow: 0 1px rgba(255, 255, 255, 0.1);
    /* margin: auto; */
    font-size: 20px;
    text-align: center;
    align-self: center;
    bottom:0px;
}
.our-team{
  cursor: auto;
}
.home-textinput{font-size: 18px;}
.home-textinput1{font-size: 18px;}
.home-textarea{font-size: 18px;}


/*Animation*/
.box {
  --border-size: 3px;
  --border-angle: 0turn;
  background-image: conic-gradient(from var(--border-angle), #ffffff, rgb(255, 255, 255) 50%, rgb(255, 255, 255)), conic-gradient(from var(--border-angle), transparent 20%, #08f, #f03);
  background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover;
  background-position: center center;
  background-repeat: no-repeat;
  animation: bg-spin 3s linear infinite;
}
@keyframes bg-spin {
  to {
    --border-angle: 1turn;
 }
}
.box:hover {
  animation-play-state: paused;
}
@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}



@media(max-width: 1200px) {
  .home-container01 {

    /* height: 3243px; */
    position: relative;
  }
  .home-first {
    height: 700px;
  }
  .home-image {
    height: 700px;
  }
  .home-navbar-interactive {
    width: 100%;
  }
  .home-desktop-menu {
    width: 855px;
  }
  .home-links {
    width: auto;
    height: 46px;
    justify-content: center;
  }
  .home-text {
    font-size: 20px;
  }
  .home-text01 {
    font-size: 20px;
  }
  .home-text02 {
    font-size: 20px;
  }
  .home-text03 {
    font-size: 20px;
  }
  .home-login {
    color: rgb(82, 78, 78);
    font-size: 18px;
    background-color: rgba(255, 255, 255, 0);
  }
  .home-container02 {
    top: 207px;
    left: 0px;
    right: 0px;
    width: 763px;
    height: 279px;
    margin: auto;
    position: absolute;
  }
  .home-text12 {
    left: 0px;
    bottom: 3px;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
  }
  .home-second {
    width:100%;
    top: 700px;
    height: 680px;
    margin: auto;
    position: absolute;
  }
  .home-container03 {
    width: 100%;
  }
  .home-container04 {
    /* width: 65px; */
    height: 162px;
  }
  .home-container05 {
    width: 946px;
  }
  .home-text18 {
    color: black;
    font-size: 16px;
    font-weight: 600;
  }
  .home-text19 {
    text-align: center;
    color: black;
    font-size: 20px;
    font-weight: 600;
  }
  .home-third {
    top: 1300px;
    left: 0px;
    height: 600px;
    position: absolute;
  }
  .home-heading {
    top: 43px;
    margin: auto;
    position: absolute;
  }
  .home-teams {
    top: 120px;
    left: 0px;
    right: 0px;
    margin: auto;
    position: absolute;
  }
  .home-fourth {
    top: 1900px;
    left: 0px;
    bottom: 0px;
    height: 900px;
    position: absolute;
  }
  .home-container09 {
    top:180px;
    left: 0px;
    right: 0px;
    width: 80%;
    margin: auto;
  }
  .home-container10 {
    
    width: 54%x;
    height: 490px;
  }
  .home-form {
    width: 70%;
    height: 85%;
  }
  .home-textarea {
    width: 90%;
  }
  .home-container11 {
    top: 144px;
    right: 11px;
    width: 44%;
    height: 373px;
  }
  .home-text31 {
    color: white;
    width: 75%;
    font-size: 20px;
  }
  .home-text27 {
    top: 30px;
    color: rgb(251, 143, 9);
    position: absolute;
    font-size: 28px;
    align-self: stretch;
    margin-left: 20%;
  }
  .home-text28 {
    top: 80px;
    color: white;
    position: absolute;
    font-size: 20px;
    margin-left: 20%;
   
  }
  .home-text29 {
    top: 129px;
    color: rgb(251, 143, 9);
    position: absolute;
    font-size: 25px;
    align-self: stretch;
    margin-left: 20%;
  }
  .home-text30 {
    top: 160px;
    color: white;
    position: absolute;
    font-size: 20px;
    margin-left: 20%;
   
  }
  .footer-basic {
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    z-index: 1000;
    color: rgba(0,0,0,.5);
    text-shadow: 0 1px rgba(255, 255, 255, 0.1);
    /* margin: auto; */
    font-size: 20px;
    text-align: center;
    align-self: center;
    top: 700px;
}
.home-container08 {
  top: 10px;
  flex: 0 0 auto;
  left: 0x;
  width: 100%;
  height: 150px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
}


@media(max-width: 991px) {
  .home-container01 {
    height: 3120px;
  }
  .home-first {
    height: 872px;
  }
  .home-image {
    top: 0px;
    left: 0px;
    height: 871px;
    position: absolute;
  }
  .home-navbar-interactive {
    width: 100%;
  }
  .home-desktop-menu {
    width: 577px;
  }
  .home-container02 {
    top: 376px;
    left: 0px;
    right: 0px;
    width: 706px;
    height: 256px;
    position: static;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
  }
  .home-text11 {
    width: 709px;
    height: 105px;
    font-size: 45px;
  }
  .home-text12 {
    color: rgb(0, 0, 0);
    right: -608px;
    bottom: 404px;
    position: static;
    font-size: 25px;
    align-self: center;
    font-style: normal;
    font-weight: 500;
  }
  .home-second {
    top: 869px;
    left: 0px;
    height: 878px;
    margin: auto;
    position: absolute;
  }
  .home-product {
    top: 0px;
    left: 0px;
    position: absolute;
    align-self: center;
  }
  .home-container03 {
    top: 60px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 726px;
    margin: auto;
    position: absolute;
    align-self: center;
    align-items: center;
    flex-direction: column;
    border: unset;
  }
  .home-container04 {
    /*width: 467px;*/
    align-items: center;
    height: auto;
    margin-bottom: 0px;
  }
  .home-text14 {
    text-align: center;
  }
  .home-container05 {
    flex: 0;
    width: 100%;
    align-self: stretch;
  }
  .home-container06 {
    flex: 1;
    margin: 32px;
  }
  .home-third {
    top: 1726px;
    height: 850px;
    padding-bottom: 0px;
  }
  .home-heading {
    top: 43px;
    left: 0px;
    right: 0px;
    /*width: 302px;*/
    height: 37px;
    margin: auto;
    text-align: center;
  }
  .home-teams {
    top: 150px;
    left: 0px;
    right: 0px;
    /*height: 2300px;*/
    margin: auto;
    flex-direction: column;
  }
  .home-container1 {
    width: 70%;
    margin-right: 0px;
    margin-bottom: 48px;
  }
  .home-container2 {
    width: 70%;
    margin-right: 0px;
    margin-bottom: 48px;
  }
  .home-container3 {
    width: 70%;
    margin-right: 0px;
    margin-bottom: 48px;
  }
  .home-fourth {
    height: 700px;
    top: 2570px;
    left: 0px;
    width: 100%;
    position: absolute;
  }
  .home-container09 {
    top: 10px;
    width: 100%;
    /*height: 673px;*/
    margin: auto;
  }
  .home-container10 {
    width: 55%;
    display:none;
  }
  .home-textarea {
    width: 75%;
  }
  .home-container11 {
    width: 65%;
    left: 0px;
    margin: auto;
  }
  .footer-basic {
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    z-index: 1000;
    color: rgba(0,0,0,.5);
    text-shadow: 0 1px rgba(255, 255, 255, 0.1);
    /* margin: auto; */
    font-size: 20px;
    text-align: center;
    align-self: center;
    top: 1050px;
  } 
  .col img {
    width: 100%;
    margin: 0 20px;
    --s: 15px;
    --b: 2px;
    --c: #668284;
    aspect-ratio: 1;
    object-fit: cover;
    padding: calc(2*var(--s));
    --_g: var(--c) var(--b),#0000 0 calc(100% - var(--b)),var(--c) 0;
    background: linear-gradient( var(--_g)) 50%/100% var(--_i,100%) no-repeat, linear-gradient(90deg,var(--_g)) 50%/var(--_i,100%) 100% no-repeat;
    outline: calc(var(--w)/2) solid #0009;
    outline-offset: calc(var(--w)/-2 - 2*var(--s));
    transition: .4s;
    cursor: pointer;
  }
  .col_c{
    margin-bottom: 50px;
  }
  
}


@media(max-width: 767px) {
  .home-container01 {
    width:100%;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 1120px;
    position: absolute;
    flex-direction: column;
  }
  .home-first {
    width:100%;
    height: 800px;
  }
  .home-image {
    left: 0px;
    bottom: 0px;
    height: 800px;
    position: absolute;
    align-self: center;
  }
  .home-navbar-interactive {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 69px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-image1 {
    width: 58px;
    height: 53px;
    margin: 15px;
  }
  .home-desktop-menu {
    display: none;
  }
  .home-text {
    font-size: 15px;
  }
  .home-text01 {
    font-size: 15px;
  }
  .home-text02 {
    font-size: 15px;
  }
  .home-text03 {
    font-size: 15px;
  }
  .home-login {
    color: rgb(82, 78, 78);
    font-size: 15px;
    background-color: rgba(255, 255, 255, 0);
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-text04 {
    margin-bottom: 16px;
  }
  .home-text05 {
    margin-left: 0;
    margin-bottom: 16px;
  }
  .home-text06 {
    margin-left: 0;
    margin-bottom: 16px;
  }
  .home-text07 {
    margin-left: 0;
    margin-bottom: 16px;
  }
  .home-text08 {
    margin-left: 0;
    margin-bottom: 16px;
  }
  .home-container02 {
    top: 250px;
    left: 0px;
    right: 0px;
    width: 469px;
    height: 238px;
    margin: auto;
    position: absolute;
  }
  .home-text11 {
    width: 445px;
    height: 54px;
    font-size: 35px;
  }
  .home-text12 {
    top: 74px;
    right: 0px;
    margin: auto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
  .home-second {
    top: 800px;
    left: -1px;
    width: 100%;
    height: 1200px;
    margin: 0px;
    position: absolute;
    align-items: center;
    margin-bottom: 0px;
    justify-content: center;
  }
  .home-product {
    top: 0px;
    right: 0px;
    width: 100%;
    height: 1150px;
    margin: auto;
    position: absolute;
  }
  .home-container03 {
    top: 41px;
    left: 0px;
    right: 0px;
    width: 85%;
    height: 1085px;
    margin: auto;
    padding: 32px;
    position: absolute;
  }
  .home-container04 {
    top: 0px;
    left: 0px;
    right: 0px;
    height: auto;
    margin: auto;
    position: absolute;
    align-self: center;
    flex-direction: column;
  }
  .home-text13 {
    margin-top: 10px;
  }
  .home-text14 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .home-container05 {
    top: 200px;
    flex: 0 0 auto;
    left: 0px;
    right: 0px;
    width: 100%;
    margin: auto;
    position: absolute;
    align-self: stretch;
    flex-direction: column;
  }
  .home-container06 {
    flex: 0 0 auto;
    width: 100%;
    margin: 10px;
  }
  .home-image2 {
    width: 70%;
    height: auto;
  }
  .home-container07 {
    width: 100%;
    margin: 10px;
  }
  .home-image3 {
    width: 70%;
    height: auto;
  }
  .home-third {
    margin:0px;
    width:100%;
    top: 2000px;
    left: 0px;
    height: 1500px;
  }
  .home-teams {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-fourth {
    top: 3500px;
  }
  .home-container10 {
    display: none;
  }
  .home-container11 {
    left: 0px;
    right: 0px;
    width: 70%;
    margin: auto;
  }
  .col{
    flex:none !important;
  }
  .col img{
    width: 40% !important;
  }
  .footer-basic {
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    z-index: 1000;
    color: rgba(0,0,0,.5);
    text-shadow: 0 1px rgba(255, 255, 255, 0.1);
    /* margin: auto; */
    font-size: 20px;
    text-align: center;
    align-self: center;
    top: 1000px;
  }
}


@media(max-width: 479px) {
  .home-container {
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
  }
  .home-container01 {
    bottom: 0px;
    height: 2725px;
    padding: 0px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .home-first {
    width:100%;
    height: 517px;
    padding-bottom: 0px;
  }
  .home-image {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 517px;
    position: absolute;
  }
  .home-navbar-interactive {
    width: 100%;
    height: 58px;
    padding: 16px;
    padding-right: 16px;
  }
  .home-image1 {
    width: 48px;
    height: 44px;
  }
  .home-desktop-menu {
    width: 212px;
  }
  .home-text {
    font-size: 10px;
    margin-right: 5px;
  }
  .home-text01 {
    font-size: 10px;
    margin-right: 5px;
  }
  .home-text02 {
    font-size: 10px;
    margin-right: 5px;
  }
  .home-text03 {
    font-size: 10px;
    margin-right: 5px;
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-links1 {
    width: 104px;
  }
  .home-container02 {
    top: 182px;
    left: 127px;
    width: 302px;
    height: 154px;
    position: static;
    padding-right: 0px;
  }
  .home-text11 {
    width: 302px;
    height: 34px;
    font-size: 25px;
  }
  .home-text12 {
    top: 31px;
    left: -2px;
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
  }
  .home-second {
    top:510px;
    left: 0px;
    right: 0px;
    bottom: -8px;
    height: 1100px;
    position: absolute;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffebcf;
  }
  .home-product {
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    position: static;
    align-items: center;
    justify-content: center;
  }
  .home-container03 {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    margin: auto;
    padding-top: 32px;
    border-width: 0px;
    padding-left: 16px;
    margin-bottom: 0px;
    padding-right: 16px;
    padding-bottom: 32px;
  }
  .home-container04 {
    top: 45px;
    left: 0px;
    right: 0px;
    width: 85%;
    height: 104px;
    margin: auto;
    padding: 0px;
    padding-left: 0px;
  }
  .home-text13 {
    font-size: 35px;
  }
  .home-container05 {
    padding:10px;
    height: auto;
    margin: 0px;
    align-items: center;
    justify-content: center;
  }
  .home-container06 {
    height: auto;
    margin: auto;
    padding: 20px;
  }
  .home-button {
    align-self: center;
    margin-top: 10px;
  }
  .home-container07 {
    flex: 0;
    height: auto;
    margin: auto;
    padding: 20px;
  }
  .home-button1 {
    align-self: center;
    margin-top: 10px;
  }
  .home-third {
    height: 1650px;
    top:1550px;
    right: 0px;
    bottom: -2014px;
  }
  .home-teams {
    top:80px;
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 32px;
  }
  .home-fourth {
    top: 3200px;
    height: 650px;
  }
  .home-container09 {
    width: 100%;
  }
  .home-container11 {
    left: 0px;
    right: 0px;
    margin: auto;
    width:90%;
  }
  .footer-basic {
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    z-index: 1000;
    color: rgba(0,0,0,.5);
    text-shadow: 0 1px rgba(255, 255, 255, 0.1);
    /* margin: auto; */
    font-size: 12px;
    text-align: center;
    align-self: center;
    top: 800px;
  }
  .product_text{
    font-size: 15px;
  }
  .home-text31{
    font-size: 17px;
  }
  .home-container07{
    margin:10px;
    width:90%;
  }
  .home-heading{
    font-size: 35px;
  }
  .home-text20{
    font-size: 35px;
  }
  .col_c{
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .col img {
    width: 80% !important;
  }
}

@media(max-width: 320px) {
  .home-container {
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
  }
  .home-container01 {
    bottom: 0px;
    height: 2725px;
    padding: 0px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .home-first {
    width:100%;
    height: 517px;
    padding-bottom: 0px;
  }
  .home-image {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 517px;
    position: absolute;
  }
  .home-navbar-interactive {
    width: 100%;
    height: 58px;
    padding: 16px;
    padding-right: 16px;
  }
  .home-image1 {
    width: 48px;
    height: 44px;
  }
  .home-desktop-menu {
    width: 212px;
  }
  .home-text {
    font-size: 10px;
    margin-right: 5px;
  }
  .home-text01 {
    font-size: 10px;
    margin-right: 5px;
  }
  .home-text02 {
    font-size: 10px;
    margin-right: 5px;
  }
  .home-text03 {
    font-size: 10px;
    margin-right: 5px;
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-links1 {
    width: 104px;
  }
  .home-container02 {
    top: 182px;
    left: 127px;
    width: 100%;
    height: 154px;
    position: static;
    padding-right: 0px;
  }
  .home-text11 {

    height: 34px;
    font-size: 25px;
  }
  .home-text12 {
    text-align:center;
    top: 31px;
    left: -2px;
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
  }
  .home-second {
    top:510px;
    left: 0px;
    right: 0px;
    bottom: -8px;
    height: 900px;
    position: absolute;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffebcf;
  }
  .home-product {
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    position: static;
    align-items: center;
    justify-content: center;
  }
  .home-container03 {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    margin: auto;
    padding-top: 32px;
    border-width: 0px;
    padding-left: 16px;
    margin-bottom: 0px;
    padding-right: 16px;
    padding-bottom: 32px;
  }
  .home-container04 {
    top: 45px;
    left: 0px;
    right: 0px;
    width: 85%;
    height: 104px;
    margin: auto;
    padding: 0px;
    padding-left: 0px;
  }
  .home-text13 {
    font-size: 30px;
  }
  .home-container05 {
    padding:10px;
    height: auto;
    margin: 0px;
    align-items: center;
    justify-content: center;
  }
  .home-container06 {
    height: auto;
    margin: auto;
    padding: 20px;
  }
  .home-button {
    align-self: center;
    margin-top: 10px;
  }
  .home-container07 {
    flex: 0;
    height: auto;
    /*margin: auto;*/
    padding: 20px;
  }
  .home-button1 {
    align-self: center;
    margin-top: 10px;
  }
  .home-third {
    height: 1500px;
    top:1410px;
    right: 0px;
    bottom: -2014px;
  }
  .home-teams {
    top:80px;
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 32px;
  }
  .home-fourth {
    top: 2900px;
  }
  .home-container09 {
    width: 100%;
  }
  .home-container11 {
    left: 0px;
    right: 0px;
    margin: auto;
    width:90%;
  }
  .footer-basic {
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    z-index: 1000;
    color: rgba(0,0,0,.5);
    text-shadow: 0 1px rgba(255, 255, 255, 0.1);
    /* margin: auto; */
    font-size: 12px;
    text-align: center;
    align-self: center;
    top: 750px;
  }
  .product_text{
    font-size: 15px;
  }
  .home-text31{
    font-size: 15px;
  }
  .home-container07{
    width:90%;
  }
  .home-heading{
    font-size: 30px;
  }
  .home-text20{
    font-size: 30px;
  }
  .col_c{
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .col img {
    width: 80% !important;
  }
}

@media(max-width: 240px) {
  .home-container {
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
  }
  .home-container01 {
    bottom: 0px;
    height: 2725px;
    padding: 0px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .home-first {
    width:100%;
    height: 517px;
    padding-bottom: 0px;
  }
  .home-image {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 517px;
    position: absolute;
  }
  .home-navbar-interactive {
    width: 100%;
    height: 58px;
    padding: 16px;
    padding-right: 16px;
  }
  .home-image1 {
    width: 48px;
    height: 44px;
  }
  .home-desktop-menu {
    width: 212px;
  }
  .home-text {
    font-size: 10px;
    margin-right: 5px;
  }
  .home-text01 {
    font-size: 10px;
    margin-right: 5px;
  }
  .home-text02 {
    font-size: 10px;
    margin-right: 5px;
  }
  .home-text03 {
    font-size: 10px;
    margin-right: 5px;
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-links1 {
    width: 104px;
  }
  .home-container02 {
    top: 182px;
    left: 127px;
    width: 100%;
    height: 154px;
    position: static;
    padding-right: 0px;
  }
  .home-text11 {

    height: 34px;
    font-size: 25px;
  }
  .home-text12 {
    text-align:center;
    top: 31px;
    left: -2px;
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
  }
  .home-second {
    top:510px;
    left: 0px;
    right: 0px;
    bottom: -8px;
    height: 900px;
    position: absolute;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffebcf;
  }
  .home-product {
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    position: static;
    align-items: center;
    justify-content: center;
  }
  .home-container03 {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    margin: auto;
    padding-top: 32px;
    border-width: 0px;
    padding-left: 16px;
    margin-bottom: 0px;
    padding-right: 16px;
    padding-bottom: 32px;
  }
  .home-container04 {
    top: 45px;
    left: 0px;
    right: 0px;
    width: 85%;
    height: 104px;
    margin: auto;
    padding: 0px;
    padding-left: 0px;
  }
  .home-text13 {
    font-size: 25px;
  }
  .home-container05 {
    padding:10px;
    height: auto;
    margin: 0px;
    align-items: center;
    justify-content: center;
  }
  .home-container06 {
    height: auto;
    margin: auto;
    padding: 20px;
  }
  .home-button {
    align-self: center;
    margin-top: 10px;
  }
  .home-container07 {
    flex: 0;
    height: auto;
    /*margin: auto;*/
    padding: 20px;
  }
  .home-button1 {
    align-self: center;
    margin-top: 10px;
  }
  .home-third {
    height: 1450px;
    top:1410px;
    right: 0px;
    bottom: -2014px;
  }
  .home-teams {
    top:80px;
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 32px;
  }
  .home-fourth {
    top: 2850px;
  }
  .home-container09 {
    width: 100%;
  }
  .home-container11 {
    left: 0px;
    right: 0px;
    margin: auto;
    width:90%;
  }
  .footer-basic {
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    z-index: 1000;
    color: rgba(0,0,0,.5);
    text-shadow: 0 1px rgba(255, 255, 255, 0.1);
    /* margin: auto; */
    font-size: 12px;
    text-align: center;
    align-self: center;
    top: 800px;
  }
  .product_text{
    font-size: 15px;
  }
  .home-text31{
    font-size: 15px;
  }
  .home-container07{
    width:90%;
  }
  .home-heading{
    font-size: 25px;
  }
  .home-text20{
    font-size: 25px;
  }
  .col_c{
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .col img {
    width: 80% !important;
  }
  .home-button1 {
    font-size: 15px;
  }
  .home-text30 {
    font-size: 17px;
  }
  .home-text28 {
    font-size: 17px;
  }
}

