
@import url("https://fonts.googleapis.com/css?family=Gochi+Hand");
@import url("https://fonts.googleapis.com/css?family=Exo:100");

/*Animation1*/
@-webkit-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
 }
}
@-moz-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
 }
}
@-o-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
 }
}
@keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
 }
}
@-webkit-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
 }
}
@-moz-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
 }
}
@-o-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
 }
}
@keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
 }
}


/*Animation2*/
.btn-shine {
  position: absolute;
  transform: translate(-50%, -50%);
  color: #fff;
  background: linear-gradient(to right, #4d4d4d 0, #fff 10%, #4d4d4d 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
}
@-moz-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@-webkit-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@-o-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}



/*MainCSS*/

  html {
    width: 100% !important;
    font-family: Inter;
    font-size: 16px;
  }
  body {
    width:100% !important;
    font-weight: 400;
    font-style:normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
    line-height: 1.15;
  }

  /*H2-Animation*/
  h2 {
    margin-bottom:10px;
    line-height: 1.5;
    display: inline;
    background-image: linear-gradient(
          transparent 50%,
          #e1fffe 50%,
          #7FFFD4 85%,
          transparent 85%,
          transparent 100%
      );
      background-repeat: no-repeat;
      background-size: 0% 100%;
      animation: animatedBackground 2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
  }

  @keyframes animatedBackground {
      to {
          background-size: 100% 100%;
      }
  }

 
 

  .p-home-container {
    width: 100% !important;
    display: flex;
    overflow: auto;
    min-height: auto !important;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .p-home-container1 {
    width: 100% !important;
    height: auto;
    display: flex;
    position: relative;
    align-items: flex-end;
    border-radius: 4px;
    justify-content: flex-start;
  }
  .p-home-first {
    top: 0px;
    flex: 0 0 auto;
    left: 0px;
    width: 100% !important;
    min-height: auto !important;
    display: flex;
    position: absolute;
    align-items: flex-start;
    justify-content: flex-start;
    background-size:cover;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC") repeat 0 0;
    -webkit-animation: bg-scrolling-reverse 0.92s infinite;
    -moz-animation: bg-scrolling-reverse 0.92s infinite;
    -o-animation: bg-scrolling-reverse 0.92s infinite;
    animation: bg-scrolling-reverse 0.92s infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -o-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  .p-home-navbar-interactive {
    width: 100%;
    height: 145px;
    display: flex;
    z-index: 10;
    position: absolute;
    align-items: center;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    justify-content: space-between;
  }
  .p-home-image {
    width: 120px;
    height: 106px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .p-home-desktop-menu {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  .p-home-links {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }
  .p-home-text {
    color: var(--dl-color-gray-black);
    font-size: 25px;
    margin-right: 20px;
    text-transform: capitalize;
  }
  .p-home-text01 {
    color: var(--dl-color-gray-black);
    font-size: 25px;
    margin-left: 32px;
    margin-right: 20px;
    text-transform: capitalize;
  }
  .p-home-text02 {
    font-size: 25px;
    margin-left: 32px;
    margin-right: 20px;
    text-transform: capitalize;
  }
  .p-home-text03 {
    font-size: 25px;
    margin-left: 32px;
    margin-right: 20px;
    text-transform: capitalize;
  }
  .p-home-buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .p-home-login {
    color: rgb(82, 78, 78);
    font-size: 25px;
    margin-left: 20px;
    border-width: 0px;
    margin-right: 20px;
    background-color: rgba(255, 255, 255, 0);
  }
  .p-home-burger-menu {
    display: none;
  }
  .p-home-icon {
    width: var16px;
    height: var16px;
  }
  .p-home-mobile-menu {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: none;
    padding: 32px;
    z-index: 200;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
  }
  .p-home-nav {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .p-home-top {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    justify-content: space-between;
  }
  .p-home-logo {
    height: 2rem;
  }
  .p-home-close-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-home-icon02 {
    width: var16px;
    height: var16px;
  }
  .p-home-links1 {
    flex: 0 0 auto;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .p-home-text04 {
    margin-bottom: 16px;
  }
  .p-home-text05 {
    margin-bottom: 16px;
  }
  .p-home-text06 {
    margin-bottom: 16px;
  }
  .p-home-text07 {
    margin-bottom: 16px;
  }
  .p-home-text08 {
    margin-bottom: 16px;
  }
  .p-home-buttons1 {
    display: flex;
    margin-top: 16px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .p-home-login1 {
    margin-right: 32px;
  }
  .p-home-icon04 {
    width: var16px;
    height: var16px;
    margin-right: 32px;
  }
  .p-home-icon06 {
    width: var16px;
    height: var16px;
    margin-right: 32px;
  }
  .p-home-icon08 {
    width: var16px;
    height: var16px;
  }
  .p-home-hero {
   
    width:100%;
    height: 100%;
    display: flex;
    padding: 100px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .p-home-container2 {
    display: flex;
    margin-right: 10px;
    padding-right: 40px;
    margin-top: 50px;
    flex-direction: column;
  }
  .p-home-text11 {
    width: auto;
    font-size: 3rem;
    color: #008080;
  }
  .p-home-text12 {
    font-size: 18px;
    /*padding:10px;*/
    /*margin-top: 32px;
    margin-bottom: 32px;*/
  }
  .p-home-image1 {
    width: 434px;
    object-fit: cover;
  }
  .right {
    display: inline;
    padding-right:10px;
    float: left;
    } 
    
    .left {
    display: inline;
    /*width: 400px;*/
    padding-right:10px;
    float: left;
    }
    .footer-basic{
      top: unset !important;
      width: 100%;
      position: fixed;
      bottom: 3% !important;
  }
  

  .table-container {
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    
  }
  
  td {
    
    padding: 8px;
  }
  
  @media (max-width: 600px) {
    td {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
  
    td:before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      margin-bottom: 5px;
    }
  
    td:nth-of-type(odd):before {
      clear: both;
    }
  }


  @media(max-width: 1200px) {
    .p-home-container1 {
      height: auto;
      position: relative;
    }
    .p-home-first {
      width:100% !important;
      height: auto;
    }
    .p-home-navbar-interactive {
      width: 100%;
    }
    .p-home-desktop-menu {
      width: 855px;
    }
    .p-home-links {
      width: 1174px;
      height: 46px;
      justify-content: center;
    }
    .p-home-text {
      font-size: 20px;
    }
    .p-home-text01 {
      font-size: 20px;
    }
    .p-home-text02 {
      font-size: 20px;
    }
    .p-home-text03 {
      font-size: 20px;
    }
    .p-home-login {
      color: rgb(82, 78, 78);
      font-size: 18px;
      background-color: rgba(255, 255, 255, 0);
    }
    .p-home-hero {
      width: 100%;
      /*padding: 130px;*/
    }
    .p-home-container2 {
         width: 90%; 
    margin-right: 20px;
    padding-right: 0px;
    /*margin-top: 350px;*/
    }
    .p-home-text11 {
      font-size: 2.5rem;
    }
    .p-home-image1 {
      width: 284px;
      height: auto;
    }
    .right {
      /*min-width: 400px;*/
      padding:10px;
      float: left;
      } 
      
      .left {
      /*min-width: 400px;*/
      padding:10px;
      float: left;
      }
  }
  @media(max-width: 991px) {
    .p-home-container1 {
      height: auto;
    }
    .p-home-first {
      top: 0px;
      left: 0px;
   
      position: absolute;
      align-self: center;
    
    }
    .p-home-navbar-interactive {
      width: 100%;
    }
    .p-home-desktop-menu {
      width: 577px;
    }
    .p-home-hero {
      /*padding: 150px;*/
      flex-direction: column;
    }
    .p-home-container2 {
      margin-top: 0px;
      align-items: center;
      margin-right: 0px;
      margin-bottom: 32px;
      padding-right: 0px;
    }
    .p-home-text11 {
      text-align: center;
    }
    .p-home-text12 {
      text-align: center;
      /* padding-left: 48px;
      padding-right: 48px; */
    }
    .p-home-image1 {
      width: 300px;
    }
    .right {
      /*min-width: 400px;*/
      padding:10px;
      float: left;
      } 
      
      .left {
      /*min-width: 400px;*/
      padding:10px;
      float: left;
      }
  }
  @media(max-width: 767px) {
    .p-home-container1 {
      top: 0px;
      left: 0px;
      right: 0px;
      height: auto;
      position: absolute;
      flex-direction: column;
    }
    .p-home-first {
      top: -1px;
      right: -1px;

    }
    .p-home-navbar-interactive {
      top: 0px;
      left: 0px;
      width: 100%;
      height: 69px;
      padding-left: 32px;
      padding-right: 32px;
    }
    .p-home-image {
      width: 58px;
      height: 53px;
      margin: 15px;
    }
    .p-home-desktop-menu {
      display: none;
    }
    .p-home-text {
      font-size: 15px;
    }
    .p-home-text01 {
      font-size: 15px;
    }
    .p-home-text02 {
      font-size: 15px;
    }
    .p-home-text03 {
      font-size: 15px;
    }
    .p-home-login {
      color: rgb(82, 78, 78);
      font-size: 15px;
      background-color: rgba(255, 255, 255, 0);
    }
    .p-home-burger-menu {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .p-home-text04 {
      margin-bottom: 16px;
    }
    .p-home-text05 {
      margin-left: 0;
      margin-bottom: 16px;
    }
    .p-home-text06 {
      margin-left: 0;
      margin-bottom: 16px;
    }
    .p-home-text07 {
      margin-left: 0;
      margin-bottom: 16px;
    }
    .p-home-text08 {
      margin-left: 0;
      margin-bottom: 16px;
    }
    .p-home-hero {
      padding-left:0px;
      padding-right: 0px;
      /* padding-left: 32px;
      padding-right: 32px;  */
    }
    .p-home-text12 {
      font-size:18px;
      /* padding-left: 16px;
      padding-right: 16px; */
    }
    .p-home-image1 {
      width: 50%;
    }
    .right {
      /*min-width: 400px;*/
      padding:10px;
      float: left;
      } 
      
      .left {
      /*min-width: 400px;*/
      padding:10px;
      float: left;
      }
  }
  @media(max-width: 479px) {
    .p-home-container {
      align-items: flex-start;
      flex-direction: row;
      justify-content: flex-start;
    }
    .p-home-container1 {
      bottom: 0px;
      height: auto;
      padding: 0px;
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
    }
    .p-home-first {
      /*height: 517px;*/
      padding-bottom: 0px;
    }
    .p-home-navbar-interactive {
      width: 100%;
      height: 58px;
      padding: 16px;
      padding-right: 16px;
    }
    .p-home-image {
      width: 48px;
      height: 44px;
    }
    .p-home-desktop-menu {
      width: 212px;
    }
    .p-home-text {
      font-size: 10px;
      margin-right: 5px;
    }
    .p-home-text01 {
      font-size: 10px;
      margin-right: 5px;
    }
    .p-home-text02 {
      font-size: 10px;
      margin-right: 5px;
    }
    .p-home-text03 {
      font-size: 10px;
      margin-right: 5px;
    }
    .p-home-mobile-menu {
      padding: 16px;
    }
    .p-home-links1 {
      width: 104px;
    }
    .p-home-hero {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .p-home-container2 {
      margin-bottom: 16px;
    }
    .p-home-text11 {
      width: 100%;
      font-size: 30px;
    }
    .right {
      /*min-width: 400px;*/
      padding:10px;
      float: none;
      } 
      
      .left {
      /*min-width: 400px;*/
      padding:10px;
      float: none;
      }
  }
  @media(max-width: 320px) {
    .p-home-container {
      align-items: flex-start;
      flex-direction: row;
      justify-content: flex-start;
    }
    .p-home-container1 {
      bottom: 0px;
      height: auto;
      padding: 0px;
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
    }
    .p-home-first {
      /*height: 517px;*/
      padding-bottom: 0px;
    }
    .p-home-navbar-interactive {
      width: 100%;
      height: 58px;
      padding: 16px;
      padding-right: 16px;
    }
    .p-home-image {
      width: 48px;
      height: 44px;
    }
    .p-home-desktop-menu {
      width: 212px;
    }
    .p-home-text {
      font-size: 10px;
      margin-right: 5px;
    }
    .p-home-text01 {
      font-size: 10px;
      margin-right: 5px;
    }
    .p-home-text02 {
      font-size: 10px;
      margin-right: 5px;
    }
    .p-home-text03 {
      font-size: 10px;
      margin-right: 5px;
    }
    .p-home-mobile-menu {
      padding: 16px;
    }
    .p-home-links1 {
      width: 104px;
    }
    .p-home-hero {
      /*height:100px;*/
      padding-top: 100px;
      /* padding-left: 20px;
      padding-right: 20px; */
      padding-bottom: 100px;
    }
    .p-home-container2 {
      width:100%;
      margin-bottom: 16px;
    }
    .p-home-text11 {
      font-size:25px;
      width: 100%;
    }
    .right {
      /*min-width: 400px;*/
      padding:10px;
      float: none;
      } 
      
      .left {
      /*min-width: 400px;*/
      padding:10px;
      float: none;
      }
  }
  @media(max-width: 240px) {
    .p-home-container {
      align-items: flex-start;
      flex-direction: row;
      justify-content: flex-start;
    }
    .p-home-container1 {
      bottom: 0px;
      height: auto;
      padding: 0px;
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
    }
    .p-home-first {
      /*height: 517px;*/
      padding-bottom: 0px;
    }
    .p-home-navbar-interactive {
      width: 100%;
      height: 58px;
      padding: 16px;
      padding-right: 16px;
    }
    .p-home-image {
      width: 48px;
      height: 44px;
    }
    .p-home-desktop-menu {
      width: 212px;
    }
    .p-home-text {
      font-size: 10px;
      margin-right: 5px;
    }
    .p-home-text01 {
      font-size: 10px;
      margin-right: 5px;
    }
    .p-home-text02 {
      font-size: 10px;
      margin-right: 5px;
    }
    .p-home-text03 {
      font-size: 10px;
      margin-right: 5px;
    }
    .p-home-mobile-menu {
      padding: 16px;
    }
    .p-home-links1 {
      width: 104px;
    }
    .p-home-hero {
      padding-top: 100px;
      /* padding-left: 20px;
      padding-right: 20px; */
      padding-bottom: 100px;
    }
    .p-home-container2 {
      margin-bottom: 16px;
    }
    .p-home-text11 {
      width: 100%;
    }
    .right {
      /*min-width: 400px;*/
      padding:10px;
      float: none;
      } 
      
      .left {
      /*min-width: 400px;*/
      padding:10px;
      float: none;
      }
  }
  