.page-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .page-container1 {
    width: 100%;
    height: 1003px;
    display: flex;
    align-items: flex-start;
  }
  .page-container2 {
    flex: 0 0 auto;
    width: 25%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url("https://images.unsplash.com/photo-1566305977571-5666677c6e98?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDcyfHx3aGl0ZSUyMHNpbXBsZXxlbnwwfHx8fDE3MDA5Mzc4MTV8MA&ixlib=rb-4.0.3&h=1100");
  }
  .page-container3 {
    width: 100%;
    height: 378px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page-image {
    width: 257px;
    height: 229px;
    object-fit: cover;
  }
  .page-container4 {
    width: 100%;
    height: 633px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .page-button {
    color: var(--dl-color-gray-black);
    margin: 15px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    background-color: #ffffff;
  }
  .page-button1 {
    margin: 15px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  .page-text {
    font-size: 30px;
    align-self: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .page-button2 {
    color: #ffffff;
    background-color: #1c1b1b;
  }
  .page-text03 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
  .page-container5 {
    flex: 0 0 auto;
    width: 75%;
    height: 100%;
    display: flex;
    position: relative;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .page-container6 {
    width: 100%;
    height: 178px;
    display: flex;
    align-self: center;
    align-items: flex-start;
    justify-content: center;
  }
  .page-navbar-interactive {
    width: 100%;
    display: none;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .page-desktop-menu {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  .page-burger-menu {
    display: none;
  }
  .page-icon {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .page-mobile-menu {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: none;
    padding: 32px;
    z-index: 100;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
  }
  .page-nav {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .page-top {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-threeunits);
    justify-content: space-between;
  }
  .page-close-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page-icon2 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .page-links {
    flex: 0 0 auto;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .page-text07 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .page-text08 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .page-buttons {
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .page-login {
    margin-right: var(--dl-space-space-twounits);
  }
  .page-text09 {
    margin: 50px;
    font-size: 50px;
    align-self: center;
    text-align: center;
  }
  .page-container7 {
    flex: 1;
    width: 100%;
    height: 988px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .page-container8 {
    width: 100%;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    height: 63px;
    display: flex;
    align-items: flex-start;
  }
  @media(max-width: 1200px) {
    .page-container2 {
      width: 25%;
    }
    .page-container5 {
      width: 75%;
    }
    .page-text09 {
      font-size: 50px;
    }
  }
  @media(max-width: 991px) {
    .page-container5 {
      width: 75%;
    }
    .page-container6 {
      width: 100%;
    }
  }
  @media(max-width: 767px) {
    .page-container2 {
      display: none;
    }
    .page-container5 {
      width: 100%;
    }
    .page-container6 {
      height: 215px;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
    }
    .page-navbar-interactive {
      width: 767px;
      display: flex;
      margin-right: 0SixUnits;
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .page-desktop-menu {
      display: none;
    }
    .page-burger-menu {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .page-icon {
      fill: #0b4c1a;
      width: var(--dl-size-size-small);
      height: var(--dl-size-size-small);
    }
    .page-text06 {
      color: rgb(6, 82, 44);
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
    }
    .page-text07 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .page-text08 {
      margin-left: 0;
      margin-bottom: var(--dl-space-space-unit);
    }
    .page-login {
      color: #ffffff;
      background-color: #0b4c1a;
    }
    .page-text09 {
      margin: 10px;
    }
  }
  @media(max-width: 479px) {
    .page-container6 {
      height: 175px;
    }
    .page-navbar-interactive {
      width: 476px;
      padding: var(--dl-space-space-unit);
      margin-right: 0px;
      padding-bottom: 16px;
    }
    .page-icon {
      fill: #0b4c1a;
    }
    .page-mobile-menu {
      padding: 16px;
    }
    .page-text06 {
      color: rgb(3, 63, 33);
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
    }
    .page-login {
      color: #ffffff;
      background-color: #0b4c1a;
    }
  }
  

/* 

.wrapper {
  animation: scroll 100s linear infinite;
  background: url("https://images.unsplash.com/photo-1465146633011-14f8e0781093?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3450&q=80"),
    #111111;
  color: #eee;
  height: 100vh;
  min-width: 360px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  perspective-origin: 50% 50%;
}

@keyframes scroll {
  100% {
    background-position: 0px -400%;
  }
}


@media (prefers-reduced-motion) {
  .wrapper {
    animation: scroll 800s linear infinite;
  }
} */

  