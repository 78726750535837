.Main-Window {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;

   -drag: none;  
   user-select: none;
   -moz-user-select: none;
   -webkit-user-drag: none;
   -webkit-user-select: none;
   -ms-user-select: none;
}

.Web-Header {
  background-color: #d6fae4;
  height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 10px;

}


.Web-Header p {
  margin: 10px;
  color:black;
  font-size: 20px;
}

.Website-Icon {
  margin-right:auto;
  margin-left: 40px;
  height: 80%;
}

.Mobile-Product {
  height: 100%;
  display: flex;
  flex:1;
  margin: auto;
  max-width: 30vh;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Mobile-Product p {
  font-family: serif;
  padding-bottom: 10px;
}

.Mobile-Product img {
  height: 60%;
  max-height: 300px;
  border-radius: 50%;
}

.Mobile-Profile {
  display: flex;
  flex:1;
  margin: auto;
  max-width: 33vh;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-bottom: 10px;  
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Mobile-Profile p {
  margin: 12px 0px;
}

.Mobile-header {
  background-color: #d6fae4;
  height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 10px;

}

.Mobile-Block{
  margin: 10px 10px 50px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top:20px;
}

.Mobile-Block:first-of-type img{
  height: 100%;
  width: 100%;
  opacity: 0.6;
}

.Mobile-Block:first-of-type{
  padding-top:0px;
  margin: 10px;  
  background: linear-gradient(to right,rgba(247,247,49,1) 0%,rgba(22, 255, 185, 0.274) 103%);
  overflow: hidden;
}

.Mobile-IntroTitle {
  font-size: 30px;
}

.Mobile-Icon {
  height: 80%;
}

.Mobile-subtitle {
  font-family: rubik,sans-serif;
  text-align: center;
  width: 100%;
}