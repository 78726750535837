/* .Container {
    align-self: 'center';     
    display: 'flex';
    align-Content: 'center';
    justify-content: 'center';
    border-color: 'black';
    border-Width: '1px';
    flex-wrap: 'wrap';
    margin-Left: 'auto';
    margin-Right: 'auto';
    padding: '50px';
    background-Color: 'white';
    max-Height: '100vh';
    max-Width: '100%';
    overflow: 'hidden';
}

.TextInput {
    background-Color:'#b7c9bc';
    margin: '10px'
}

.SubmitButton {
    border-Color: '#c7c0b3';
    margin-Top: '10px';
    margin-Bottom: '10px';    
}

.Icon {
    width: '150px';
    margin-Bottom: '30px';
    margin-Left: 'auto';
    margin-Right: 'auto';
}

.Text {
    margin-Top: '10px';
    margin-Bottom: '10px'
}

.Line {
    height: '1px';
    color: 'grey';
    width: '100%';
    background-Color: 'grey';
    opacity: '0.4';
    
}

.ForgetPw {
    width: 'fit-content';
    font-Size: '8px';
   
}


 */

.login-page-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #b9b9b9; 
  }
  .login-page-container1 {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .login-page-container2 {
    top: 0px;
    flex: 0 0 auto;
    left: 0px;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .login-page-image {
    width: 100%;
    height: 1011px;
    align-self: flex-start;
    object-fit: cover;
  }
  .login-page-container3 {
    top: 0px;
    flex: 0 0 auto;
    right: 0px;
    width: 50%;
    height: 100%;
    display: flex;
    position: absolute;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .login-page-container4 {
    width: 350px;
    height: 757px;
    align-self: center;
    background-color: white;
  }
  .login-page-container5 {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 45%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .login-page-image1 {
    top: 0px;
    left: 0px;
    right: 0px;
    width: 247px;
    bottom: 0px;
    height: 241px;
    margin: auto;
    align-self: center;
    margin-top: 0px;
    object-fit: cover;
    margin-bottom: 0px;
  }
  .login-page-container6 {
    left: 0px;
    right: 0px;
    width: 100%;
    bottom: 0px;
    height: 55%;
    margin: auto;
    display: flex;
    align-self: center;
    /* align-items: flex-start; */
    flex-direction: column;
  }
  .login-page-text {
    font-size: 18px;
    align-self: center;
    font-style: normal;
    margin-top: 0px;
    font-weight: 700;

    margin-bottom: 10px;
  }
  .login-page-textinput {
    align-self: center;
    margin-bottom: 10px;
    height: 40px;
    width: 60%;
    font-size: 15px;
  }
  .login-page-text1 {
    font-size: 18px;
    align-self: center;
    font-style: normal;
    margin-top: 0px;
    text-align: justify;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .login-page-textinput1 {
    align-self: center;
    margin-bottom: 10px;
    height: 40px;
    width: 60%;
    font-size: 15px;
  }
  .login-page-button {
    color: #ffffff;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    border-width: 0px;
    background-color: #35a365;
    width: 80px;
    height: 40px;
    font-size: 15px;
    margin-top: 10px;
  }
  .login-page-text2 {
    align-self: center;
    margin-top: 50px;
    
  }
  .login-page-text2_short {
    align-self: center;
    margin-top: 3px;
    
  }
  .login-page-text5 {
    align-self: center;
  }
  @media(max-width: 991px) {
    .login-page-container1 {
      width: 100%;
      height: 1001px;
      position: relative;
    }
    .login-page-image {
      width: 100%;
    }
    .login-page-container3 {
      height: 100%;
      align-self: stretch;
      align-items: center;
      justify-content: center;
    }
    .login-page-container5 {
      width: 100%;
    }
    .login-page-container6 {
      left: 0px;
      right: 0px;
      margin: auto;
    }
  }
  @media(max-width: 767px) {
    .login-page-container1 {
      width: 100%;
      height: 100%;
      z-index: 1;
      position: relative;
    }
    .login-page-container2 {
      width: 100%;
      z-index: 1;
    }
    .login-page-image {
      width: 100%;
      height: 100%;
      opacity: 0.65;
      z-index: 1;
    }
    .login-page-container3 {
      width: 100%;
      z-index: 100;
    }
    .login-page-container4 {
      width: 350px;
    }
  }
  @media(max-width: 479px) {
    .login-page-container1 {
      height: 1002px;
      z-index: 1;
    }
    .login-page-image {
      width: 100%;
      opacity: 0.65;
      z-index: -1;
    }
    .login-page-container4 {
      height: 80%;
    }
  }
  



@import url("https://fonts.googleapis.com/css?family=Lobster&display=swap") repeat;

  